import React from 'react';

function TermosDeUso() {


    return (
        <div style={{ padding: '20px', maxWidth: '1100px', margin: '0 auto' }}>

            <h1>TERMOS E CONDIÇÕES GERAIS DE USO DO APLICATIVO</h1>
            <p><strong>MyHelp</strong>, inscrita no CNPJ/MF sob o nº 54.323.056/0001-06, com endereço na Rua Manoel Rodrigues Jacob, 1451, CEP 14.802-195, Araraquara - SP, é uma pessoa jurídica de direito privado prestadora de serviços de anúncios online, realizados entre promitentes contratantes (“Contratante”) e promitentes Anunciantes de serviço e locação de bens móveis (“Anunciantes/Contratados(as)”), por meio do aplicativo (“Plataforma”). Por intermédio destes Termos e Condições Gerais de Uso (“Termos”), a MyHelp apresenta aos usuários em geral, Contratantes e Anunciantes (em conjunto denominados “Usuários”) as condições essenciais para o uso dos serviços e de locações de bens móveis oferecidos na Plataforma. Ao utilizar a Plataforma ou utilizar os serviços ofertados pela MyHelp, os Usuários (“Contratante” e “Anunciantes/Contratados(as))” aceitam e se submetem às condições destes Termos e às Políticas de Privacidade, bem como a todos os documentos anexos a estes. Sendo os seguintes pontos contratados:</p>

            <h2>1º – OBJETO</h2>
            <p>a. Os serviços objeto dos presentes Termos consistem em:</p>
            <ul>
                <li>Permitir aos Contratantes que utilizem a Plataforma para livremente e sem direcionamento ou interferência busquem e quotem orçamentos de Anunciantes;</li>
                <li>O Modelo de Utilização de Créditos, apresenta os orçamentos requeridos pelos(as) Contratantes aos Anunciantes (“Orçamentos”), que poderão oferecer ou não os seus Serviços ou Locações de Bens Móveis (“Serviços”) ao(à) Contratante;</li>
                <li>Viabilizar o contato direto entre Anunciantes e Contratantes interessados em adquirir os Serviços ou Locações de Bens Móveis, por meio da divulgação das informações de contato de uma parte à outra;</li>
            </ul>
            <p>b. A MyHelp, portanto, possibilita que os Usuários se contatem e negociem entre si diretamente, sem intervir no contato, na negociação ou na efetivação dos negócios, não sendo, nesta qualidade, fornecedora de quaisquer Serviços ou proprietária de quaisquer bens móveis anunciados por seus Usuários na Plataforma;</p>
            <p>c. Na qualidade de classificado de Serviços, a MyHelp não impõe ou interfere em qualquer negociação sobre condição, valor, qualidade, forma ou prazo da contratação entre os Contratantes e Anunciantes, tampouco garante a qualidade, ou entrega dos Serviços ou Locações de Bens Móveis contratados entre os Usuários;</p>
            <p>d. Ao se cadastrar, o Usuário poderá utilizar todos os serviços disponibilizados na Plataforma disponíveis para sua região, declarando, para tanto, ter lido, compreendido e aceitado estes Termos.</p>

            <h2>2º – DAS PARTES</h2>
            <p>Os serviços da MyHelp estão disponíveis para pessoas físicas e pessoas jurídicas regularmente inscritas nos cadastros de contribuintes federal e estaduais que tenham capacidade legal para contratá-los. Não podem utilizá-los, assim, pessoas que não gozem dessa capacidade, inclusive menores de idade ou pessoas que tenham sido inabilitadas na plataforma MyHelp, temporária ou definitivamente. Ficam, desde já, os Usuários advertidos das sanções legais cominadas pela Legislação Brasileira em vigor;</p>
            <p>b. É vedada a criação de mais de um cadastro por Usuário. Em caso de multiplicidade de cadastros elaborados por um só Usuário, a MyHelp se reserva ao Direito a seu exclusivo critério e sem necessidade de prévia anuência ou comunicação aos Usuários, inabilitar todos os cadastros existentes e impedir eventuais cadastros futuros vinculados a estes:</p>
            <ul>
                <li>Somente será permitida a vinculação de um cadastro por CPF, telefone ou e-mail, não podendo haver duplicidade de dados em nenhum caso;</li>
            </ul>
            <p>c. A MyHelp pode unilateralmente excluir o cadastro dos Usuários quando verificado que a conduta do Usuário é ou será prejudicial ou ofensiva a outros Usuários, à própria MyHelp e seus funcionários ou a terceiros.</p>

            <h2>3º – CADASTRO</h2>
            <p>a. É necessário o preenchimento completo de todos os dados pessoais exigidos pela MyHelp no momento do cadastramento, para que o Usuário esteja habilitado a utilizar a Plataforma;</p>
            <p>b. É de exclusiva responsabilidade dos Usuários fornecer, atualizar e garantir a veracidade dos dados cadastrais, não cabendo à MyHelp qualquer tipo de responsabilidade civil e/ou criminal resultante de dados inverídicos, incorretos ou incompletos fornecidos pelos Usuários;</p>
            <p>c. A MyHelp se reserva ao direito de utilizar todos os meios válidos e possíveis para identificar seus Usuários, bem como solicitar dados adicionais e documentos que entenda serem pertinentes, a fim de conferir os dados pessoais informados;</p>
            <p>d. A MyHelp, caso considere um cadastro, ou as informações nele contidas, suspeito de conter dados errôneos ou inverídicos, se reservará esta ao direito de suspender, temporária ou definitivamente, o Usuário responsável pelo cadastramento, assim como impedir e bloquear qualquer publicidade ou cadastro de Serviços, bem como cancelar anúncios publicados por este, sem prejuízo de outras medidas que entenda necessárias e oportunas. No caso de aplicação de quaisquer destas sanções, não assistirá aos Usuários direito a qualquer tipo de indenização ou ressarcimento por perdas e danos, lucros cessantes ou danos morais;</p>
            <p>e. O Usuário acessará sua conta por meio de apelido (login) e senha, comprometendo-se a não informar a terceiros esses dados, responsabilizando-se integralmente pelo uso que deles seja feito;</p>
            <p>f. O Usuário compromete-se a notificar a MyHelp imediatamente, por meio dos canais de contato mantidos pela MyHelp na Plataforma, a respeito de qualquer uso não autorizado de sua conta. O Usuário será o único responsável pelas operações efetuadas em sua conta, uma vez que o acesso só será possível mediante a utilização de senha de seu exclusivo conhecimento;</p>
            <p>g. Em nenhuma hipótese será permitida a cessão, venda, aluguel ou outra forma de transferência da conta. Não se permitirá, ainda, a criação de novos cadastros por pessoas cujos cadastros originais tenham sido cancelados por infrações às políticas da MyHelp;</p>
            <p>h. O apelido que o Usuário utiliza na MyHelp não poderá guardar semelhança com o nome MyHelp. Tampouco poderá ser utilizado qualquer apelido que insinue ou sugira que os Serviços ou Anúncios de Locações serão prestados pela MyHelp ou que façam parte de promoções suas. Também serão eliminados apelidos considerados ofensivos ou que infrinjam a legislação em vigor;</p>
            <p>i. Não é permitido aos Anunciantes se apresentarem aos Contratantes como funcionários, Anunciantes de serviço e/ou locações ou contratados da MyHelp, bem como utilizarem o nome, marca ou logo da MyHelp. Os Contratantes entendem que os Anunciantes não são subordinados à My Help de nenhuma forma;</p>
            <p>j. A MyHelp se reserva o Direito de, unilateralmente e sem prévio aviso, recusar qualquer solicitação de cadastro e de cancelar um cadastro previamente aceito.</p>


            <h2>4º – MODIFICAÇÕES DOS TERMOS E CONDIÇÕES GERAIS</h2>
            <p>a. A MyHelp poderá alterar, a qualquer tempo e a seu único e exclusivo critério, estes Termos. Os novos Termos entrarão em vigor 10 (dez) dias depois de publicados na Plataforma. No prazo de 5 (cinco) dias contados a partir da publicação das modificações, o Usuário deverá informar, por e-mail, caso não concorde com os termos alterados. Nesse caso, o vínculo contratual deixará de existir, desde que não haja contas ou dívidas em aberto. Não havendo manifestação no prazo estipulado, entender-se-á que o Usuário aceitou tacitamente os novos Termos, e o contrato continuará vinculando as partes;</p>
            <ul>
                <li>As alterações não vigorarão em relação a negociações entre Anunciante e Contratante já iniciados ao tempo em que tais alterações sejam publicadas. Apenas para estes, os Termos valerão com a redação anterior;</li>
            </ul>
            <p>b. Os serviços oferecidos pela MyHelp poderão ser diferentes para cada região do país. Estes Termos deverão ser interpretados de acordo com a região em que foi efetuado o cadastro do Anunciante.</p>

            <h2>5º – ACEITE DE ORÇAMENTOS</h2>
            <p>a. No Modelo de Utilização de Créditos dentro da plataforma, os Contratantes prestarão informações básicas sobre os Serviços e Locações de Bens Móveis que pretendem contratar e os Orçamentos serão disponibilizados aos Anunciantes que poderão, de livre vontade, escolher se terão acesso às informações de contato do Contratante;</p>
            <p>b. Os dados de contato do(a) Contratante somente serão disponibilizados ao(à) Anunciante após o desconto de um número pré-determinado de Créditos, que poderão ser adquiridos pelo(a) Anunciante;</p>
            <p>c. O(a) Anunciante reconhece que, ao dispender Créditos para visualizar os dados de contato do Contratante, ele apenas pagará para ter acesso a estes dados, não podendo responsabilizar a MyHelp pelo insucesso de uma eventual negociação com o(a) Contratante, qualquer que seja o motivo, incluindo dados cadastrais desatualizados ou incorretos inseridos pelo(a) Contratante.</p>

            <h2>6º – PRIVACIDADE DA INFORMAÇÃO</h2>
            <p>- Todas as informações ou os dados pessoais prestados pelo Usuário da MyHelp são protegidas.</p>

            <h2>7º – TARIFAS</h2>
            <p>a. Os Anunciantes poderão adquirir Créditos resgatáveis que servirão como moeda virtual usada para acionar as funcionalidades da Plataforma MyHelp, principalmente o acesso aos contatos dos Contratantes;</p>
            <p>b. Os Créditos poderão ser adquiridos online no site de Créditos, pelo aplicativo da MyHelp, ou por meio do atendimento, sendo claro que todas as interações com o time de atendimento serão gravadas e armazenadas nos termos da Política de Privacidade da MyHelp;</p>
            <p>c. Os Créditos são pessoais e intransferíveis e estão vinculadas à conta do Anunciante;</p>
            <p>d. O preço e disponibilidade dos Créditos e dos Orçamentos estão sujeitos a alterações sem aviso prévio, podendo haver variação dinâmica baseada em fatores como local, tipo e oferta de orçamentos;</p>
            <p>e. A MyHelp poderá oferecer condições especiais para a aquisição de créditos ou atendimento personalizado para profissionais que se destaquem na utilização da plataforma;</p>
            <p>f. O Anunciante é o único responsável por verificar se a quantidade correta de Créditos foi adicionada ou deduzida de sua conta durante qualquer transação. É obrigação do Anunciante informar a MyHelp sobre qualquer falha ou erro;</p>
            <p>g. Em nenhum caso, Créditos bonificados, premiados, dados ou de qualquer forma oferecidas sem custo ao Anunciante serão reembolsadas ou transformadas em dinheiro. O Anunciante aceita que a MyHelp poderá extinguir, excluir ou reduzir os Créditos bonificados e nenhuma indenização ou ressarcimento serão devidos neste caso.</p>

            <h2>8º – REGIME DE TRIBUTAÇÃO E EMISSÃO DE NOTAS FISCAIS PELOS SERVIÇOS, LOCAÇÕES DE BENS MÓVEIS E DEMAIS CONTRATAÇÕES</h2>
            <p>a. Desde 03/2024, a MyHelp está enquadrada no Regime do Simples Nacional para Emissão de Notas Fiscais, conforme autorização expedida pela Receita Federal do Brasil;</p>
            <p>b. O Regime enquadrado de Tributação Simples permite à MyHelp emitir Notas Fiscais de Serviço de forma diferenciada a seus Anunciantes, fazendo-o da seguinte forma:</p>
            <ul>
                <li>Anunciantes pessoa física (profissionais autônomos e MEI): será emitida uma única NFS-e MENSAL para cada código de serviço, consolidando o valor total da receita auferida, no mês, com os serviços prestados pela MyHelp; e</li>
                <li>Anunciantes pessoa jurídica: será emitida uma NFS-e mensal por tomador pessoa jurídica e por código de serviço, consolidando o valor da receita auferida, no mês, com os serviços prestados a cada um dos tomadores pessoas jurídicas.</li>
            </ul>

            <h2>9º – POLÍTICA DE CANCELAMENTO</h2>
            <p>a. Os Créditos adquiridos pelo Anunciante não serão devolvidas ao Anunciante ou convertidas em moeda corrente, caso o Anunciante decida parar de usar a Plataforma MyHelp;</p>
            <p>b. Os Créditos adquiridos terão validade de 3 (três) meses a partir do momento da aquisição. Após esse período, os Créditos vencidos serão descontados da conta do Anunciante, que não poderá reclamar nenhum direito sobre eles;</p>
            <ul>
                <li>A MyHelp poderá criar condições especiais de validade dos Créditos para casos específicos ou ofertas pontuais;</li>
            </ul>
            <p>c. O Anunciante que não deseja realizar a renovação automática do Modelo de Utilização de Créditos deverá, em até 7 (sete) dias após a renovação automática e apenas caso não tenha utilizado Créditos adquiridos em razão da renovação, informar à MyHelp que não deseja efetuar a renovação. Após esse período, será cobrada multa no valor de 20% do valor contratado em razão da rescisão. Caso o Anunciante tenha utilizado Créditos referente à renovação, não será permitida a rescisão.</p>

            <h2>10º – SERVIÇOS E ANÚNCIOS E ORÇAMENTOS PROIBIDOS</h2>
            <p>a. A MyHelp é uma Plataforma de classificados online de Serviços e Anúncios de Locações de Bens Móveis, estando proibida a veiculação de qualquer anúncio ou pedido de orçamento de venda, troca ou qualquer forma de transferência de posse ou propriedade de qualquer bem imóvel;</p>
            <p>b. Estão proibidas também a veiculação de anúncios de serviços ilegais de acordo com a legislação vigente ou que possam ser considerados ofensivos a terceiros;</p>
            <p>c. A MyHelp excluirá, unilateralmente e sem qualquer comunicação prévia, aquele Usuário que desrespeitar as regras contidas nesta seção;</p>
            <p>d. A MyHelp não realiza uma curadoria prévia dos anúncios veiculados na Plataforma;</p>
            <p>e. Qualquer Usuário ou pessoa física ou jurídica que se sentir ofendido por qualquer anúncio veiculado na Plataforma poderá requisitar à MyHelp que exclua aquele anúncio da Plataforma, pelos seus canais de atendimento.</p>

            <h2>11º – SISTEMA DE QUALIFICAÇÃO DOS PRESTADORES</h2>
            <p>a. Os Anunciantes aceitam e se submetem ao sistema de qualificação adotado pela MyHelp;</p>
            <p>b. Os Anunciantes concordam que a MyHelp poderá cancelar, excluir ou suspender por tempo indeterminado cadastros que apresentem qualificações negativas de forma reiterada;</p>
            <p>c. Os Anunciantes entendem que as contas são pessoais e intransferíveis. A MyHelp poderá suspender ou excluir, sem aviso prévio, qualquer Anunciante em caso de suspeita de transferência, onerosa ou não, para qualquer terceiro;</p>
            <p>d. Não assistirá aos Anunciantes qualquer tipo de indenização ou ressarcimento por perdas e danos, lucros cessantes e danos morais, em razão da qualificação atribuída aos Serviços anunciados;</p>
            <p>e. Tendo em vista que os comentários postados são opiniões pessoais dos Usuários, estes serão responsáveis pelas opiniões publicadas na Plataforma, seja perante a MyHelp, perante os Anunciantes, terceiros, órgãos governamentais ou demais Usuários do site, isentando a MyHelp de qualquer responsabilidade relativa à veiculação dos comentários;</p>
            <ul>
                <li>A MyHelp não excluíra comentários ou qualificações sobre os Anunciantes. Somente o Usuário responsável pelo comentário ou qualificação poderá remover ou alterar os comentários ou qualificações;</li>
            </ul>
            <p>f. A MyHelp se reserva ao direito de excluir unilateralmente e a seu exclusivo critério, comentários que contenham e cadastros de Usuários que pratiquem:</p>
            <ul>
                <li>Ofensa à honra, imagem, reputação e dignidade de terceiros;</li>
                <li>Pornografia, pedofilia, e outras modalidades de satisfação sexual;</li>
                <li>Racismo ou discriminação de qualquer natureza;</li>
                <li>Bullying, Stalking ou qualquer outra espécie de constrangimento ilegal ou assédio;</li>
                <li>Manifesta violação a direito autoral ou direito de imagem;</li>
                <li>Utilização de marcas, símbolos, logotipos ou emblemas de terceiros;</li>
                <li>Instigação ou apologia à prática de crimes, como tráfico ou uso de entorpecentes, estupro, homicídio, estelionato, dentre outros;</li>
                <li>Erros ou suspeita de equívocos.</li>
            </ul>

            <h2>12º – OBRIGAÇÕES DOS USUÁRIOS</h2>
            <p>a. O Anunciante deve ter capacidade legal para prestar o Serviço e para Locar e Dispor do Bem Móvel;</p>
            <p>b. Em virtude de a MyHelp não figurar como parte nas transações de contratação dos Serviços e de Locações de Bens Móveis que se realizam entre os Usuários, a responsabilidade por todas as obrigações delas decorrentes, sejam fiscais, trabalhistas, consumeristas ou de qualquer outra natureza, será exclusivamente do Contratante, do Anunciante ou de ambos, conforme o caso. Na hipótese de interpelação judicial que tenha como ré a MyHelp, cujos fatos fundem-se em ações do Anunciante, este será chamado ao processo, devendo arcar com todos os ônus que daí decorram, incluindo despesas com taxas, emolumentos, acordos, honorários advocatícios entre outros. Por não figurar como parte nas transações que se realizam entre os Usuários, a MyHelp também não pode obrigar os Usuários a honrarem suas obrigações ou a efetivarem a negociação;</p>
            <p>c. O Anunciante deverá ter em mente que, na medida em que atue como um fornecedor de serviços ou como locador de bens móveis, sua oferta o vincula, nos termos do artigo 30 do Código de Defesa do Consumidor e do artigo 429 do Código Civil, cujo cumprimento poderá ser exigido judicialmente pelo Contratante;</p>
            <p>d. A MyHelp não se responsabiliza pelas obrigações tributárias que recaiam sobre as atividades dos Usuários. Assim como estabelece a legislação pertinente em vigor, o Contratante deverá exigir nota fiscal do Anunciante em suas transações. O Anunciante, nos moldes da lei vigente, responsabilizar-se-á pelo cumprimento da integralidade das obrigações oriundas de suas atividades, notadamente aquelas referentes a tributos incidentes.</p>

            <h2>13º – PRÁTICAS VEDADAS</h2>
            <p>a. É terminantemente vedado aos Usuários, entre outras atitudes previstas nestes Termos, manipular, direta ou indiretamente, os preços dos Serviços anunciados;</p>
            <p>b. É proibido aos Anunciantes divulgar o mesmo anúncio, conteúdo, item ou serviço em mais de uma categoria e/ou de forma repetida. A MyHelp se reserva o direito de excluir anúncios ou serviços repetidos, assim como suspender ou excluir o cadastro do Anunciante responsável pela duplicidade;</p>
            <p>c. Os Usuários não poderão:</p>
            <ul>
                <li>Obter, guardar, divulgar, comercializar e/ou utilizar dados pessoais sobre outros Usuários para fins comerciais ou ilícitos;</li>
                <li>Usar meios automáticos, incluindo spiders, robôs, crawlers, ferramentas de captação de dados ou similares para baixar dados do site (exceto ferramentas de busca na Internet e arquivos públicos não comerciais);</li>
                <li>Burlar, ou tentar burlar, de qualquer forma que seja, o sistema, mecanismo e/ou a Plataforma do site;</li>
                <li>Incluir meios de contato como telefone, e-mail, endereço e outras formas de comunicação nas ofertas.</li>
            </ul>

            <h2>14º – VIOLAÇÃO NO SISTEMA OU DA BASE DE DADOS</h2>
            <p>- É vedada a utilização de dispositivo, software ou outro recurso que possa interferir nas atividades e nas operações de Plataforma, bem como nos anúncios, nas descrições, nas contas ou em seus bancos de dados. Qualquer intromissão, tentativa de, ou atividade que viole ou contrarie as leis de direito de propriedade intelectual e as proibições estipuladas nestes Termos tornará o responsável passível de sofrer os efeitos das ações legais pertinentes, bem como das sanções aqui previstas, sendo ainda responsável por indenizar q MyHelp ou seus Usuários por eventuais danos causados.</p>

            <h2>15º – SANÇÕES</h2>
            <p>a. Sem prejuízo de outras medidas, a MyHelp poderá, a seu exclusivo critério e sem necessidade de prévia anuência dos ou comunicação aos Usuários, advertir, suspender ou cancelar, temporária ou permanentemente, o cadastro ou os anúncios do Usuário, podendo aplicar sanção que impacte negativamente em sua reputação, a qualquer tempo, iniciando as ações legais cabíveis e suspendendo a prestação de seus serviços e anúncios de locação de bens, se:</p>
            <ul>
                <li>o Usuário não cumprir qualquer dispositivo destes Termos e as demais políticas da MyHelp;</li>
                <li>descumprir com seus deveres de Usuário;</li>
                <li>praticar atos delituosos ou criminais;</li>
                <li>não puder ser verificada a identidade do Usuário, qualquer informação fornecida por ele esteja incorreta ou se as informações prestadas levarem a crer que o cadastro seja falso ou de pessoa diversa;</li>
                <li>A MyHelp entender que os anúncios ou qualquer outra atitude do Usuário tenham causado algum dano a terceiros ou à MyHelp ou tenham a potencialidade de assim o fazer;</li>
            </ul>
            <p>b. Nos casos de suspensão, temporária ou permanente, do cadastro do Anunciante, todos os anúncios ativos serão automaticamente cancelados;</p>
            <ul>
                <li>O Anunciante excluído ou suspenso por qualquer motivo perderá todos os Créditos contratados. No caso da suspensão, caso, ao final da investigação do caso, a conta do Anunciante seja plenamente reestabelecida, os Créditos perdidos serão devolvidos para a conta do Anunciante;</li>
                <li>Em caso de suspensão permanente ou exclusão do Anunciante em razão de comprovação ou suspeita de fraude contra a MyHelp ou Contratantes, a MyHelp não devolverá os Créditos ou quaisquer valores já dispendidos pelo Anunciante;</li>
            </ul>
            <p>c. A MyHelp se reserva o direito de, a qualquer momento e a seu exclusivo critério, solicitar o envio de documentação pessoal ou de qualquer documento que comprove a veracidade das informações cadastrais;</p>
            <p>d. Em caso de requisição de documentos, quaisquer prazos determinados nestes Termos só serão aplicáveis a partir da data de recebimento dos documentos solicitados ao Anunciante pela MyHelp.</p>

            <h2>16º – RESPONSABILIDADES</h2>
            <p>a. A MyHelp não se responsabiliza por vícios ou defeitos técnicos e/ou operacionais oriundos do sistema do Usuário ou de terceiros;</p>
            <p>b. A MyHelp não é responsável pela entrega dos Serviços e Bens em Locação anunciados pelos Anunciantes na Plataforma;</p>
            <p>c. A MyHelp tampouco se responsabiliza pela existência, quantidade, qualidade, estado, integridade ou legitimidade dos Serviços e Bens em Locação oferecidos ou contratados pelos Usuários, assim como pela capacidade para contratar dos Usuários ou pela veracidade dos dados pessoais por eles fornecidos. A MyHelp, por não ser proprietária, depositante ou detentora dos produtos oferecidos, não outorga garantia por vícios ocultos ou aparentes nas negociações entre os Usuários. Cada Usuário conhece e aceita ser o único responsável pelos Serviços e Bens em Locação que anuncia ou pelas ofertas que realiza;</p>
            <p>d. A MyHelp não será responsável por ressarcir seus Usuários por quaisquer gastos com ligações telefônicas, pacotes de dados, SMS, mensagens, e-mails, correspondência ou qualquer outro valor despendido pelo Usuário em razão de contato com a MyHelp ou quaisquer outros Usuário, por qualquer motivo que o seja;</p>
            <p>e. A MyHelp não poderá ser responsabilizada pelo efetivo cumprimento das obrigações assumidas pelos Usuários. Os Usuários reconhecem e aceitam que, ao realizar negociações com outros Usuários, fazem-no por sua conta e risco, reconhecendo a MyHelp como mero fornecedor de serviços de disponibilização de espaço virtual para anúncio dos Serviços e Bens Móveis em Locação ofertados por terceiros;</p>
            <p>f. Em nenhum caso a MyHelp será responsável pelo lucro cessante ou por qualquer outro dano e/ou prejuízo que o Usuário possa sofrer devido às negociações realizadas ou não realizadas por meio de Plataforma, decorrentes da conduta de outros Usuários;</p>
            <p>g. Por se tratar de negociações realizadas por meio eletrônico entre dois Usuários que não se conheciam previamente à negociação, a MyHelp recomenda que toda transação seja realizada com cautela e prudência;</p>
            <p>h. Caso um ou mais Usuários ou algum terceiro inicie qualquer tipo de reclamação ou ação legal contra outro ou outros Usuários, todos e cada um dos Usuários envolvidos nas reclamações ou ações eximem de toda responsabilidade a MyHelp e seus diretores, gerentes, empregados, agentes, operários, representantes e procuradores;</p>
            <p>i. A MyHelp se reserva o direito de auxiliar e cooperar com qualquer autoridade judicial ou órgão governamental, podendo enviar informações cadastrais ou negociais de seus Usuários, quando considerar que seu auxílio ou cooperação sejam necessários para proteger seus Usuários, funcionários, colaboradores, administradores, sócios ou qualquer pessoa que possa ser prejudicada pela ação ou omissão combatida.</p>

            <h2>17º – ALCANCE DOS SERVIÇOS E BENS MÓVEIS EM LOCAÇÃO</h2>
            <p>a. Estes Termos não geram nenhum contrato de sociedade, de mandato, de franquia ou relação de trabalho entre MyHelp e o Usuário. O Usuário manifesta ciência de que a MyHelp não é parte de nenhuma transação realizada entre Usuários, nem possui controle algum sobre a qualidade, a segurança ou a legalidade dos Serviços e dos Bens Móveis em Locação anunciados pelos Usuários, sobre a veracidade ou a exatidão dos anúncios elaborados pelos Usuários, e sobre a capacidade dos Usuários para negociar;</p>
            <p>b. A MyHelp não pode assegurar o êxito de qualquer transação realizada entre Usuários, tampouco verificar a identidade ou os dados pessoais dos Usuários. A MyHelp não garante a veracidade da publicação de terceiros que apareça em sua Plataforma e não será responsável pela correspondência ou por contratos que o Usuário realize com terceiros;</p>
            <ul>
                <li>A MyHelp poderá oferecer aos Anunciantes um serviço de conferência, para garantir a sua identidade e dados cadastrais. A MyHelp poderá informar aos Contratantes quando esse serviço foi utilizado pelo Anunciante;</li>
                <li>O Anunciante que burlar o sistema de conferência a fim de receber vantagens ilícitas será excluído da Plataforma e poderão ser tomadas medidas judiciais cabíveis, especialmente de cunho criminal.</li>
            </ul>

            <h2>18º – PROBLEMAS DECORRENTES DO USO DO SISTEMA</h2>
            <p>a. A MyHelp não se responsabiliza por qualquer dano, prejuízo ou perda sofridos pelo Usuário em razão de falhas em sua conexão com a internet, com o seu provedor, no sistema, com o sistema de SMS, com a linha telefônica ou no servidor utilizados pelo Usuário, decorrentes de condutas de terceiros, caso fortuito ou força maior;</p>
            <p>b. A MyHelp não é responsável pela compatibilidade entre a sua Plataforma e hardwares de propriedade do Usuário. O Usuário deverá manter o seu equipamento atualizado e não poderá responsabilizar MyHelp caso a Plataforma não seja acessível em equipamentos antiquados;</p>
            <p>c. A MyHelp também não será responsável por qualquer vírus, trojan, malware, spyware ou qualquer software que possa danificar, alterar as configurações ou infiltrar o equipamento do Usuário em decorrência do acesso, da utilização ou da navegação na internet, ou como consequência da transferência de dados, informações, arquivos, imagens, textos ou áudio.</p>

            <h2>19º – PROPRIEDADE INTELECTUAL E LINKS</h2>
            <p>- O uso comercial da expressão "MyHelp" como marca, nome empresarial ou nome de domínio, bem como os logos, marcas, insígnias, conteúdo das telas relativas aos serviços da Plataforma e o conjunto de programas, bancos de dados, redes e arquivos que permitem que o Usuário acesse e use sua conta, são propriedade da MyHelp e estão protegidos pelas Leis e pelos tratados internacionais de Direito Autoral, de marcas, de patentes, de modelos e de desenhos industriais. O uso indevido e a reprodução total ou parcial dos referidos conteúdos são proibidos, salvo com autorização expressa da MyHelp.</p>

            <h2>20º – DA CAPACIDADE, DO OBJETO E DO ALUGUEL</h2>
            <p>a. A capacidade do agente é aquela para os atos da vida civil em geral. O contrato de locação, não transfere propriedade. Não se trata, pois de ato de disposição. Inclui-se desse modo entre os atos considerados de administração. Assim, a exigência de outorga conjugal presente na compra e venda não se faz necessária na locação, como regra geral. O pai e o tutor podem dar em locação os bens dos filhos;</p>
            <p>b. Quanto ao objeto da coisa locada, o art. 565 da Legislação Civil adverte que se trata de bem não fungível. Isso porque incumbe ao locatário restituí-la ao locador uma vez findo o contrato, nos termos do art. 569, IV da mesma Lei. Ficam, portanto, excluídas da locação às coisas consumíveis ou fungíveis. O objeto do contrato deve ser apto à utilização e fruição pelo locador (Contratante). Objeto inidôneo oferecido pelo Anunciante, neste ou em qualquer outro contrato, torna nulo o negócio. Destarte, nula será a locação de objeto ilícito, o que autoriza a MyHelp a restringir ou excluir da plataforma o Usuário praticante de tais ofertas de locações.</p>
            <p>c. O preço do aluguel é de responsabilidade exclusiva do Anunciante/Usuário , onde a plataforma MyHelp não possui qualquer interesse ou proveito, salvo somente nos custos dos serviços de intermediação. O aluguel é devido durante o tempo em que a coisa estiver à disposição do Usuário/Contratante/locatário, ainda que dela não se utilize.</p>

            <h2>21º – INDENIZAÇÃO</h2>
            <p>- O Usuário indenizará a MyHelp, suas filiais, empresas controladas, controladores diretos ou indiretos, diretores, administradores, colaboradores, representantes e empregados, inclusive quanto a honorários advocatícios, por qualquer demanda promovida por outros Usuários ou terceiros, decorrentes das atividades do primeiro na Plataforma, de quaisquer descumprimentos, por aquele, dos Termos e das demais políticas da MyHelp ou, ainda, de qualquer violação, pelo Usuário, de Lei ou de Direitos de terceiros.</p>

            <h2>22º – LEGISLAÇÃO APLICÁVEL E FORO DE ELEIÇÃO</h2>
            <p>- Todos os itens destes Termos são regidos pelas leis vigentes na República Federativa do Brasil. Para todos os assuntos referentes à interpretação, ao cumprimento ou a qualquer outro questionamento relacionado a estes Termos, as partes concordam em se submeter ao Foro da Comarca de Araraquara, Estado de São Paulo.</p>

            <h2>23º – SERVIÇOS E ANÚNCIOS E LOCAÇÃO DE BENS MÓVEIS E ORÇAMENTOS PROIBIDOS</h2>
            <p>a. A MyHelp é uma Plataforma de classificados online de Serviços, estando proibida a veiculação de qualquer anúncio ou pedido de orçamento de venda, aluguel, troca ou qualquer forma de transferência de posse ou propriedade de qualquer bem imóvel;</p>
            <p>b. Estão proibidas também a veiculação de anúncios de serviços ilegais de acordo com a legislação vigente ou que possam ser considerados ofensivos a terceiros;</p>
            <p>c. A MyHelp excluirá, unilateralmente e sem qualquer comunicação prévia, aquele Usuário que desrespeitar as regras contidas nesta seção;</p>
            <p>d. A MyHelp não realiza uma curadoria prévia dos anúncios veiculados na Plataforma;</p>
            <p>e. Qualquer Usuário ou pessoa física ou jurídica que se sentir ofendido por qualquer anúncio veiculado na Plataforma poderá requisitar à MyHelp que exclua aquele anúncio de sua Plataforma, pelos seus canais de atendimento.</p>

            <a href={require("./termos.pdf")} download>Download</a>
        </div>
    );
}

export default TermosDeUso;
