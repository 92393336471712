import React, { useState } from 'react';
import { Alert, Button, Form } from 'react-bootstrap';
import axios from 'axios';


import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function DeletarConta() {
    const [confirmacao, setConfirmacao] = useState(false);

    const handleChange = (event) => {
        setConfirmacao(!confirmacao);
    }

    const handleSubmit = (event) => {
        //request
        const request = async () => {
            try {
            const response = await axios.post(`${process.env.REACT_APP_MOBILE_API_URL}/apagar-conta`, {
                email: email,
                cpf: cpf.replaceAll('.', '').replaceAll('-', ''),
                senha: senha,
                tipo_conta: tipoConta
            });
            toast(response.data.message);
            } catch (error) {
            console.error("Error deleting account:", error);
            toast("Um erro ocorreu ao deletar a conta. Por favor tente novamente mais tarde ou entre em contato com o suporte.");
            }
        }
        request();
        event.preventDefault();

    }

    const [email, setEmail] = useState('');
    const [cpf, setCpf] = useState('');
    const [senha, setSenha] = useState('');
    const [tipoConta, setTipoConta] = useState('');

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    }

    const handleCpfChange = (event) => {
        let formattedCpf = event.target.value.replace(/\D/g, ''); // Remove non-digit characters
        formattedCpf = formattedCpf.slice(0, 11); // Limit to 11 characters
        formattedCpf = formattedCpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4'); // Format as xxx.xxx.xxx-xx
        setCpf(formattedCpf);
    }

    const handleSenhaChange = (event) => {
        setSenha(event.target.value);
    }

    const handleTipoContaChange = (event) => {
        setTipoConta(event.target.value);
    }


    return (
        <div className="d-flex flex-column align-items-center justify-content-center" style={{ height: '100vh' }}>
            <h2>Deletar sua conta do My Help</h2>
            <Form className="d-flex flex-column align-items-center" onSubmit={handleSubmit}>
                <Form.Group controlId="email">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                        type="email"
                        placeholder="Digite seu email"
                        value={email}
                        onChange={handleEmailChange}
                        className="mb-3"
                    />
                </Form.Group>
                <Form.Group controlId="cpf">
                    <Form.Label>CPF (apenas números)</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Digite seu CPF"
                        value={cpf}
                        onChange={handleCpfChange}
                        className="mb-3"
                    />
                </Form.Group>
                <Form.Group controlId="senha">
                    <Form.Label>Senha</Form.Label>
                    <Form.Control
                        type="password"
                        placeholder="Digite sua senha"
                        value={senha}
                        onChange={handleSenhaChange}
                        className="mb-3"
                    />
                </Form.Group>
                <Form.Group controlId="tipoConta">
                    <Form.Label>Tipo de Conta</Form.Label>
                    <Form.Control
                        as="select"
                        value={tipoConta}
                        onChange={handleTipoContaChange}
                        className="mb-3"
                    >
                        <option value="">Selecione o tipo de conta</option>
                        <option value="pessoal">Pessoal</option>
                        <option value="profissional">Profissional</option>
                    </Form.Control>
                </Form.Group>

                <p style={{ color: 'red', marginBottom: '1rem', marginInline: '50px' }}>
                    Atenção: Esta ação é irreversível. Ao deletar sua conta, seu acesso ao app será removido e não será mais possível acessar e utilizar o MyHelp.
                </p>
                <Form.Group controlId="confirmacao">
                    <Form.Check
                        type="checkbox"
                        label="Li e entendi as informações acima"
                        checked={confirmacao}
                        onChange={handleChange}
                        className="mb-3"
                    />
                </Form.Group>
                <Button variant="danger" type="submit" disabled={!confirmacao}>
                    Deletar Conta
                </Button>
            </Form>
            <ToastContainer />

        </div>
    );
}

export default DeletarConta;
