import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Landing from './Landing';
import TermosDeUso from './TermosDeUso'; // Importe o componente da página de Termos de Uso
import DeletarConta from './DeletarConta'; 

import 'bootstrap/dist/css/bootstrap.min.css';

import './custom.scss';



function App() {
    return (
        <Router>
            <div className="App" style={{ fontFamily: 'Raleway, sans-serif' }}>
                <Routes>
                    <Route path="/" element={<Landing />} />
                    <Route path="/termos" element={<TermosDeUso />} />
                    <Route path="/deletarConta" element={<DeletarConta />} />
                </Routes>
            </div>
        </Router>
    );
}

export default App;
